import React from "react";
import smoothscroll from "smoothscroll-polyfill";

import "@fontsource/ibm-plex-mono/100.css";
import "@fontsource/ibm-plex-mono/100-italic.css";
import "@fontsource/ibm-plex-mono/200.css";
import "@fontsource/ibm-plex-mono/200-italic.css";
import "@fontsource/ibm-plex-mono/300.css";
import "@fontsource/ibm-plex-mono/300-italic.css";
import "@fontsource/ibm-plex-mono/400.css";
import "@fontsource/ibm-plex-mono/400-italic.css";
import "@fontsource/ibm-plex-mono/500.css";
import "@fontsource/ibm-plex-mono/500-italic.css";
import "@fontsource/ibm-plex-mono/600.css";
import "@fontsource/ibm-plex-mono/600-italic.css";
import "@fontsource/ibm-plex-mono/700.css";
import "@fontsource/ibm-plex-mono/700-italic.css";
// import "@fontsource/ibm-plex-mono"; // Defaults to weight 400.

import "./src/styles/variables/colors.css";
import "./src/styles/variables/general.css";
import "./src/styles/variables/sizes.css";
import "./src/styles/variables/type.css";
import "./src/styles/global.scss";

import Layout from "./src/components/layout/Layout";
import { GMDProvider } from "./src/components/context/GMDContext";

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <GMDProvider>{element}</GMDProvider>;
};

export const onClientEntry = () => {
  smoothscroll.polyfill();
};
