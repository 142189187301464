import React, { useContext } from "react";
import { Spanlink } from "../../common/Spanlink";
import { Link } from "gatsby";
import { GMDContext } from "../../context/GMDContext";
import { currentYear } from "../../../utils/common";

import * as styles from "./Header.module.scss";

const Header = ({ className, location }) => {
  const { studentYear } = useContext(GMDContext);

  const link =
    location.pathname.split("/")[1] === "lab"
      ? ""
      : location.pathname.split("/")[1];

  // compare the current year in utils to the studentyear set in context in the student template
  const linkText = link === "" ? "lab" : link;

  return (
    <header className={styles.header} aria-label="Header">
      <nav aria-labelledby="mainmenulabel">
        <h2 id="mainmenulabel" className="visually-hidden">
          Main Menu
        </h2>
        <ul>
          <li className={styles.first}>
            <Link to="https://gmdlcc.com">GMD</Link>{" "}
            <Link to="/">
              <span className={styles.labText}>CRITICAL FORUM</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/archive">ARCHIVE</Link>
          </li> */}
          {/* <li className={styles.last}>LAB</li> */}
        </ul>
      </nav>
    </header>
  );
};

export { Header };
